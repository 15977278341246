import { useHandleErrors } from '@/hooks/useHandleErrors';
import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { ScanCode } from '@/types/ticket';
import { PeriodicWorkerInstanzeParams } from '@/types/worker';
import { getCurrentTimestampInSeconds } from '@/utils/date';
import { WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE } from '@/worker/consts';
import { WORKER_TYPE_TICKET_DOWNLOADER, createPeriodicWorker } from '@/worker/createPeriodicWorker';
import { useEffect, useState } from 'react';

export const useTicketDownloader = (syncInterval: number | undefined, scanCode?: ScanCode) => {
  const { reportError } = useHandleErrors();
  const [syncInProgress, setSyncInProgress] = useState(false);

  useEffect(() => {
    if (scanCode === undefined || scanCode === '' || syncInterval === undefined) {
      return;
    }

    const params: PeriodicWorkerInstanzeParams = {
      onStart: () => setSyncInProgress(true),
      onStop: () => {
        setSyncInProgress(false);

        const response = LocalEventStorage.updateInfosByScanCode(scanCode, {
          lastDownload: getCurrentTimestampInSeconds(),
        });

        if (!response.ok) {
          reportError(response.error, [
            'useTicketDownloader - worker',
            'error during LocalEventStorage.updateInfosByScanCode',
          ]);
        }

        // send message to update ticketCount in the main thread
        window.postMessage({ type: WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE, scanCode }, window.location.origin);
      },
      workerMsgParams: { interval: syncInterval, scanCode: scanCode },
      workerType: WORKER_TYPE_TICKET_DOWNLOADER,
    };

    const worker = createPeriodicWorker(params);
    return () => {
      if (worker === null) {
        return;
      }
      (worker as Worker).terminate();
    };
  }, [scanCode, syncInterval, reportError]);
  return { syncInProgress };
};
