import { AppStateContext } from '@/context/AppStateContext';
import { useTicketCount } from '@/hooks/useTicketCount';
import { useTicketCountAlreadyCheckedIn } from '@/hooks/useTicketCountAlreadyCheckedIn';
import { translate } from '@/i18n';
import { ScanCode } from '@/types/ticket';
import { useContext } from 'react';

interface Props {
  scanCode?: ScanCode;
}

export const TicketCount = ({ scanCode = undefined }: Props) => {
  const { ticketCount } = useTicketCount();
  const alreadyCheckedInTicketCount = useTicketCountAlreadyCheckedIn();
  const { hideTicketCount } = useContext(AppStateContext);

  return (
    <div>
      {scanCode === undefined || hideTicketCount
        ? null
        : `${alreadyCheckedInTicketCount}/${ticketCount} ${translate('common', 'tickets')}`}
    </div>
  );
};
