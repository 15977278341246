/* eslint-disable @typescript-eslint/no-magic-numbers */
type NumberFormatLocale = NonNullable<Parameters<typeof Intl.NumberFormat>[0]>;
type NumberFormatOptions = NonNullable<Parameters<typeof Intl.NumberFormat>[1]>;

const getNumberFormat = (() => {
  const cache = new Map<string, Intl.NumberFormat>();
  return ({ locale, ...options }: { locale?: NumberFormatLocale } & NumberFormatOptions) => {
    return (
      cache.get(typeof locale === 'string' ? locale : (locale ?? []).join(',')) ??
      new Intl.NumberFormat(locale, options)
    );
  };
})();

export type NumberFormatProps = {
  value: number;
  locale?: NumberFormatLocale;
} & NumberFormatOptions;

export const NumberFormat = ({ value, ...props }: NumberFormatProps) => <>{getNumberFormat(props).format(value)}</>;
