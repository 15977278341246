import { WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE } from '@/worker/consts';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getStorageWrapper } from '@/utils/storageWrapper';
import { ScanCode } from '@/types/ticket';
import { AppStateContext } from '@/context/AppStateContext';

export const useTicketCount = () => {
  const [ticketCount, setTicketCount] = useState(0);
  const { scanCode } = useContext(AppStateContext);

  const getLocalStorageCount = useCallback(async (scanCode: ScanCode) => {
    const result = await getStorageWrapper().countTicketsByScanCode(scanCode);
    if (!result.ok) {
      console.warn('error while fetching ticket count :: ', result.error);
      return 0;
    }
    return result.data;
  }, []);

  useEffect(() => {
    if (scanCode === undefined) {
      return;
    }
    const getTicketCount = async () => {
      const ticketCount = await getLocalStorageCount(scanCode);
      setTicketCount(ticketCount);
    };
    getTicketCount();

    // function to handle the message send by the useTicketDownloader-Worker
    function handleMessage(event: { origin: string; data: { type: string | undefined; scanCode: string } }) {
      if (event.origin !== window.location.origin || event.data.type === undefined) {
        return;
      }
      if (event.data.type === WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE) {
        getLocalStorageCount(event.data.scanCode).then((count: number) => {
          setTicketCount(count);
        });
      }
    }
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [getLocalStorageCount, scanCode, setTicketCount]);

  return useMemo(
    () => ({
      getLocalStorageCount,
      ticketCount,
    }),
    [getLocalStorageCount, ticketCount],
  );
};
