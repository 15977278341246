import { useTicketDownloader } from '@/hooks/useTicketDownloader';
import { useTicketCheckinUploader } from '@/hooks/useTicketCheckinUploader';
import { ScanCode } from '@/types/ticket';
import { useContext } from 'react';
import { AppStateContext } from '@/context/AppStateContext';

interface Props {
  scanCode?: ScanCode;
}

export const useBackgroundSyncer = ({ scanCode }: Props) => {
  const { syncInterval } = useContext(AppStateContext);
  const { syncInProgress } = useTicketDownloader(syncInterval, scanCode);
  const { uploadInProgress } = useTicketCheckinUploader(syncInterval);
  return syncInProgress || uploadInProgress;
};
