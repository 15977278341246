import { PeriodicWorkerInstanzeParams } from '@/types/worker';
import { WORKER_START_COMMAND, WORKER_STOP_COMMAND } from '@/worker/consts';

export const WORKER_TYPE_TICKET_DOWNLOADER = 'ticketDownloader';
export const WORKER_TYPE_CHECKINS_UPLOADER = 'checkinUploader';

export const createPeriodicWorker = ({
  workerMsgParams,
  workerType,
  onStart,
  onStop,
}: PeriodicWorkerInstanzeParams) => {
  if (typeof window === 'undefined') {
    return null;
  }

  let worker;
  switch (workerType) {
    case WORKER_TYPE_TICKET_DOWNLOADER:
      worker = new Worker(new URL('@/worker/ticket-downloader-worker.ts', import.meta.url));
      break;
    case WORKER_TYPE_CHECKINS_UPLOADER:
      worker = new Worker(new URL('@/worker/checkin-upload-worker.ts', import.meta.url));
      break;
    default:
      return null;
  }

  worker.postMessage(workerMsgParams);

  worker.onmessage = (event) => {
    switch (event.data) {
      case WORKER_START_COMMAND:
        onStart();
        break;
      case WORKER_STOP_COMMAND:
        onStop();
        break;
      default:
        break;
    }
  };

  return worker;
};
