/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useContext, useState } from 'react';
import UpdateIcon from '@mui/icons-material/Update';
import { Button, Menu, MenuItem } from '@mui/material';
import { AppStateContext } from '@/context/AppStateContext';
import { Interval, INTERVALS } from '@/components/SyncIntervalSetting/Interval';

export const SyncIntervalSetting = () => {
  const { syncInterval, updateSyncInterval } = useContext(AppStateContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setInterval = (interval: number) => {
    updateSyncInterval(interval);
    handleClose();
  };

  return (
    <>
      <Button
        id="sync-interval-button"
        color="inherit"
        endIcon={<UpdateIcon />}
        onClick={handleClick}
        aria-controls={open ? 'sync-interval-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : false}
      >
        <Interval ms={syncInterval} unitDisplay="short" />
      </Button>
      <Menu
        id="sync-interval-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'sync-interval-button',
        }}
      >
        {INTERVALS.map((ms) => (
          <MenuItem onClick={() => setInterval(ms)} key={`sync-${ms}`}>
            <Interval ms={ms} unitDisplay="long" />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
