import { ServerResponseCheckinErrorMessages, ErrorTypes, ErrorType } from '@/types/error';
import { translate } from '@/i18n';

export const getErrorMessage = (type: ErrorType) => {
  switch (type) {
    case ErrorTypes.NETWORK_ERROR:
      return 'Network Error';
    case ErrorTypes.INVALID_SCAN_CODE:
      return translate('scanCode', 'invalidScanCode');
    default:
      return 'Unknown Error';
  }
};

export const getErrorMessageTranslationKey = (error: Error) => {
  switch (error.message) {
    case ServerResponseCheckinErrorMessages.TICKET_INVALID_CODE:
      return ErrorTypes.TICKET_INVALID_CODE;
    case ServerResponseCheckinErrorMessages.TICKET_REVOKED:
      return ErrorTypes.TICKET_REVOKED;
    case ServerResponseCheckinErrorMessages.TICKET_ALREADY_CHECKEDIN:
      return ErrorTypes.TICKET_ALREADY_CHECKEDIN;
    default:
      return ErrorTypes.TICKET_NOT_FOUND;
  }
};
