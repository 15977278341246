import { AppStateContext } from '@/context/AppStateContext';
import { ScanCodeErrorContext } from '@/context/ScanCodeErrorContext';
import { useHandleErrors } from '@/hooks/useHandleErrors';
import { LocalEventStorage } from '@/persistence/localeEventStorage';
import { ok } from '@/result';
import { ErrorTypes } from '@/types/error';
import { ScanCode } from '@/types/ticket';
import { getStorageWrapper } from '@/utils/storageWrapper';
import { getTicketSourceDataWrapper } from '@/utils/ticketSourceWrapper';
import { useCallback, useContext, useMemo, useState } from 'react';

const ticketSource = getTicketSourceDataWrapper();

export const useLoadScanCodeEventData = () => {
  const { reportErrorAndShowFatalErrorPage } = useHandleErrors();
  const { setScanCodeError } = useContext(ScanCodeErrorContext);
  const { setHideTicketCount } = useContext(AppStateContext);
  const [scanCodeDataLoadFinished, setScanCodeDataLoadFinished] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadEventData = useCallback(
    async (scanCode: ScanCode) => {
      setIsLoading(true);

      const localStorageData = await setLocalStorageData(scanCode);

      if (!localStorageData.ok) {
        setScanCodeError(scanCode, ErrorTypes.INVALID_SCAN_CODE);
        setIsLoading(false);
        return;
      }

      const scanCodeTicketsInLocalStorage = await getStorageWrapper().countTicketsByScanCode(scanCode);

      if (!scanCodeTicketsInLocalStorage.ok) {
        console.warn('error while fetching ticket count :: ', scanCodeTicketsInLocalStorage.error);
        setScanCodeDataLoadFinished(true);
        setIsLoading(false);
        return;
      }

      const result = LocalEventStorage.getHideTicketCountInfoByScanCode(scanCode);

      if (!result.ok) {
        setScanCodeDataLoadFinished(true);
        setIsLoading(false);
        reportErrorAndShowFatalErrorPage(result.error, ['useLoadScanCodeEventData', 'error during loadEventData']);
        return;
      }

      setHideTicketCount(result.data);
      setScanCodeDataLoadFinished(true);
      setIsLoading(false);
    },
    [reportErrorAndShowFatalErrorPage, setScanCodeError, setHideTicketCount],
  );

  return useMemo(
    () => ({
      dataLoadingInProgress: isLoading,
      loadEventData,
      scanCodeDataLoadFinished,
    }),
    [isLoading, loadEventData, scanCodeDataLoadFinished],
  );
};

const setLocalStorageData = async (scanCode: ScanCode) => {
  const result = await ticketSource.validateScanCodeAndGetEventInfos(scanCode);

  if (!result.ok) {
    return result;
  }

  const response = LocalEventStorage.updateInfosByScanCode(scanCode, {
    eventName: result.data.text,
    hideTargetQuantity: result.data.hideTargetQuantity,
  });

  if (!response.ok) {
    return response;
  }

  LocalEventStorage.setItem('LAST_USED_SCAN_CODE', scanCode);

  return ok(undefined);
};
