import styles from '@/components/ErrorTicket/ErrorTicket.module.css';
import { translate } from '@/i18n';
import { Button, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const ErrorTicket = () => {
  const router = useRouter();

  const handleClick = useCallback(async () => {
    router.push(`/`);
  }, [router]);

  return (
    <div className={styles.ticketContainer}>
      <header>
        <Typography variant="h6" className={styles.headline}>
          {translate('fatalError', 'headline')}
        </Typography>
        <div>{translate('fatalError', 'subline')}</div>
      </header>
      <hr className={styles.horizontalLine} />
      <figure>
        <Image
          src="/assets/broken_robot.png"
          alt={translate('fatalError', 'imgAltText')}
          width={200}
          height={200}
          priority
        />
      </figure>
      <div className={styles.info}>{translate('fatalError', 'info')}</div>
      <div className={styles.ripLine}></div>
      <footer>
        <div className={styles.bottomContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            aria-label={translate('fatalError', 'button')}
          >
            {translate('fatalError', 'button')}
          </Button>
        </div>
      </footer>
    </div>
  );
};
