import { SyncIntervalSetting } from '@/components/SyncIntervalSetting';
import { ScanCode } from '@/types/ticket';
import { CircularProgress } from '@mui/material';
import { useBackgroundSyncer } from '@/hooks/useBackgroundSyncer';

interface Props {
  scanCode?: ScanCode;
}

export const SyncArea = ({ scanCode }: Props) => {
  const inProgress = useBackgroundSyncer({ scanCode });

  return inProgress ? <CircularProgress size={20} /> : <SyncIntervalSetting />;
};
