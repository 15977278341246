import { AppStateContext } from '@/context/AppStateContext';
import { useRollbar } from '@rollbar/react';
import { useCallback, useContext, useMemo } from 'react';
import Rollbar from 'rollbar';

export const useHandleErrors = () => {
  const { setShowBlockingErrorPage } = useContext(AppStateContext);
  const rollbar = useRollbar();

  const reportErrorAndShowFatalErrorPage = useCallback(
    async (error: Error, args?: string[]) => {
      reportRoolbar(error as Error, rollbar, args);
      setShowBlockingErrorPage(true);
    },
    [rollbar, setShowBlockingErrorPage],
  );

  const reportError = useCallback(
    async (error: Error, args?: string[]) => {
      reportRoolbar(error as Error, rollbar, args);
    },
    [rollbar],
  );

  const reportAsInfo = useCallback(
    async (error: Error, args?: string[]) => {
      reportRoolbar(error as Error, rollbar, args, 'info');
    },
    [rollbar],
  );

  return useMemo(
    () => ({
      reportAsInfo,
      reportError,
      reportErrorAndShowFatalErrorPage,
    }),
    [reportError, reportAsInfo, reportErrorAndShowFatalErrorPage],
  );
};

const reportRoolbar = (error: Error, rollbar: Rollbar, args?: string[], level: Rollbar.Level = 'error') => {
  const infos = args?.join(' :: ');
  if (level === 'info') {
    rollbar.info(error, infos);
  } else {
    rollbar.error(error, infos);
  }
};
