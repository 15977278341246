import { useEffect, useState } from 'react';
import { PeriodicWorkerInstanzeParams } from '@/types/worker';
import { WORKER_TYPE_CHECKINS_UPLOADER, createPeriodicWorker } from '@/worker/createPeriodicWorker';

export const useTicketCheckinUploader = (syncInterval: number | undefined) => {
  const [uploadInProgress, setUploadInProgress] = useState(false);

  useEffect(() => {
    if (syncInterval === undefined) {
      return;
    }
    const params: PeriodicWorkerInstanzeParams = {
      onStart: () => setUploadInProgress(true),
      onStop: () => setUploadInProgress(false),
      workerMsgParams: { interval: syncInterval },
      workerType: WORKER_TYPE_CHECKINS_UPLOADER,
    };

    const worker = createPeriodicWorker(params);
    return () => {
      if (worker === null) {
        return;
      }
      (worker as Worker).terminate();
    };
  }, [syncInterval]);
  return { uploadInProgress };
};
