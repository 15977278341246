import { AppStateContext } from '@/context/AppStateContext';
import { ScanCode } from '@/types/ticket';
import { getStorageWrapper } from '@/utils/storageWrapper';
import { WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE } from '@/worker/consts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useContext, useEffect } from 'react';

const getAlreadyCheckedInTicketsLocalStorageCount = async (scanCode: ScanCode) => {
  const result = await getStorageWrapper().countAlreadyCheckedInTicketsByScanCode(scanCode);
  if (!result.ok) {
    console.warn('error while fetching already checkedIn ticket count :: ', result.error);
    return 0;
  }
  return result.data;
};

export const useTicketCountAlreadyCheckedIn = () => {
  const { scanCode, hideTicketCount } = useContext(AppStateContext);
  const { data: n, refetch } = useQuery({
    enabled: !hideTicketCount && scanCode !== undefined,
    placeholderData: keepPreviousData,
    queryFn: async () => (scanCode === undefined ? 0 : getAlreadyCheckedInTicketsLocalStorageCount(scanCode)),
    queryKey: ['checked-in-tickets', scanCode],
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    // function to handle the message send by the useTicketDownloader-Worker
    const handleMessage = async (event: { origin: string; data: { type: string | undefined } }) => {
      if (event.origin !== window.location.origin || event.data.type === undefined) {
        return;
      }
      if (event.data.type === WORKER_MESSAGE_PERIODIC_DOWNLOAD_DONE) {
        await refetch();
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [refetch]);
  return n ?? 0;
};
