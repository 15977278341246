const MS_TO_SEC_DIVIDER = 1_000;

export const getCurrentTimestampInSeconds = () => {
  return Math.floor(Date.now() / MS_TO_SEC_DIVIDER);
};

export const dateFromSeconds = (timestamp: number) => {
  return new Date(timestamp * MS_TO_SEC_DIVIDER);
};

export const getTimestampInSecondsFromDate = (date: string) => {
  return Math.floor(Date.parse(date) / MS_TO_SEC_DIVIDER);
};
