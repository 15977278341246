import { NetworkStatusContext } from '@/context/NetworkStatusContext';
import { useContext } from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import WifiOffIcon from '@mui/icons-material/WifiOff';

export const NetworkStatus = () => {
  const { isOnline } = useContext(NetworkStatusContext);

  return isOnline ? <WifiIcon /> : <WifiOffIcon />;
};
