import styles from '@/components/Footer/Footer.module.css';
import { ScanCode } from '@/types/ticket';
import { NetworkStatus } from '@/components/NetworkStatus';
import { TicketCount } from '@/components/TicketCount';
import { SyncArea } from '@/components/SyncArea';

interface Props {
  scanCode?: ScanCode;
  className?: string;
}

export const Footer = ({ scanCode = undefined, className }: Props) => {
  return (
    <div className={[styles.root, className].join(' ')}>
      <div className={styles.buttonContainer}>
        <NetworkStatus />
        <TicketCount scanCode={scanCode} />
      </div>
      <div className={styles.buttonContainer}>
        <SyncArea scanCode={scanCode} />
      </div>
    </div>
  );
};
