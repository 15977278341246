import de from '@/messages/de.json';
import en from '@/messages/en.json';
import { ComponentProps } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// Intersecting ensures every message ID exists in all languages.
// As a result, new message IDs must be added to each language before they can be used.
type MessageId = keyof typeof en & keyof typeof de;

type MessageProps = ComponentProps<typeof FormattedMessage> & { id: MessageId };
export const Message = (props: MessageProps) => <FormattedMessage {...props} />;

type Values = ComponentProps<typeof FormattedMessage>['values'];
export const useMessage = (id: MessageId, values?: Values) => {
  const message = useIntl().formatMessage({ id }, values);

  if (typeof message !== 'string') {
    throw new Error(`Message ID "${id}" is not a string.`);
  }

  return message;
};

export const mapServerErrorToMessageId = (error: string): MessageId => {
  return (
    (
      {
        invalidResponse: 'error.invalid-response',
        ticketAlreadyCheckedIn: 'error.ticket-already-checked-in',
        ticketInvalidCode: 'error.ticket-invalid-code',
        ticketNotFound: 'error.ticket-no-found',
        ticketNotValidAtTime: 'error.ticket-no-valid-period',
        ticketRevoked: 'error.ticket-revoked',
      } satisfies Record<string, MessageId>
    )[error] ?? 'error.unknown'
  );
};
