/* eslint-disable @typescript-eslint/no-magic-numbers */
import { NumberFormat, NumberFormatProps } from '@/components/NumberFormat';
import { useLocale } from '@/i18n';

const MS = 1;
const S = 1_000 * MS;
const M = 60 * S;
const H = 60 * M;

export const INTERVALS = [10 * S, 30 * S, M, 5 * M, 10 * M, H];

export const Interval = ({
  ms,
  unitDisplay,
}: {
  ms: number;
  unitDisplay?: NonNullable<NumberFormatProps>['unitDisplay'];
}) => {
  const locale = useLocale();
  const unitLength = [H, M, S].find((unit) => ms >= unit) ?? S;
  const unitName = { [H]: 'hour', [M]: 'minute', [S]: 'second' }[unitLength];
  return (
    <NumberFormat value={ms / unitLength} locale={locale} style="unit" unit={unitName} unitDisplay={unitDisplay} />
  );
};
